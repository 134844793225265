import React from "react";

const AppleLogin = React.lazy(() => import("./AppleLogin"));
const GoogleLogin = React.lazy(() => import("./GoogleLogin"));
const FacebookLogin = React.lazy(() => import("./FacebookLogin"));

export const LoginProviders = {
  apple: AppleLogin,
  android: GoogleLogin,
  facebook: FacebookLogin,
};

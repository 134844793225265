import React from "react";

import { LoginProviders } from "./LoginProviders";

export { default as AxiosLoader } from "./AxiosLoader";
export { default as LabelledOutline } from "./LabelledOutline";
export { default as LanguageSelector } from "./LanguageSelector";
export { default as DialogTitle } from "./DialogTitle";
export { default as CollapsiblePanel } from "./CollapsiblePanel";

const Barcode = React.lazy(() => import("./Barcode"));
const CintoMobileAddToWallet = React.lazy(() => import("./CintoMobileAddToWallet"));
const OTPLoginVerification = React.lazy(() => import("./OTPLoginVerification"));

const AppleLogin = LoginProviders.apple;
const GoogleLogin = LoginProviders.android;
const FacebookLogin = LoginProviders.facebook;

export { Barcode, CintoMobileAddToWallet, AppleLogin, GoogleLogin, FacebookLogin, OTPLoginVerification };

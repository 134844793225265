import Cookies from "js-cookie";
import React, { Suspense, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { LinearProgress } from "@mui/material";

import App from "./App";
import neo from "./assets/neo.png";
import ResourceLoader from "./runtime/ResourceLoader";
import ServiceWorkerUpdater from "./runtime/ServiceWorkerUpdater";
import * as serviceWorker from "./serviceWorkerRegistration";
import RedirectNotFound from "./views/errors/RedirectNotFound";

const LoginCallback = React.lazy(() =>
  import("./views/AuthorizationCallback").then((module) => ({ default: module.LoginCallback }))
);
const LogoutCallback = React.lazy(() =>
  import("./views/AuthorizationCallback").then((module) => ({ default: module.LogoutCallback }))
);
const TelemetryProvider = React.lazy(() => import("./contexts/TelemetryProvider"));

const cookieTenantId = Cookies.get("tenantId");

function NeostoreApp() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <a href="https://neostore.cloud">
        <img src={neo} style={{ width: "400px", height: "400px" }} alt="" />
      </a>
      <code>{process.env.REACT_APP_VERSION}</code>
    </div>
  );
}

function CoreApp() {
  const [serverInformations, setServerInformations] = useState<{ [key: string]: any } | undefined>(undefined);

  useEffect(() => {
    const serverInformations = Object.fromEntries(
      window?.performance
        ?.getEntriesByType("navigation")?.[0]
        ?.serverTiming?.filter((o) => o.name.startsWith("neo."))
        ?.map?.(({ name, description }) => [name, description]) ?? []
    );
    setServerInformations(serverInformations);
  }, []);

  return (
    <>
      {serverInformations !== undefined && (
        <>
          {serverInformations["neo.error"] !== undefined ? (
            <>
              <ResourceLoader>
                {serverInformations["neo.error"] === "redirect.404" ? <RedirectNotFound /> : <>Unknown error</>}
              </ResourceLoader>
            </>
          ) : (
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<NeostoreApp />} />
                <Route
                  path="/auth/login"
                  element={
                    <Suspense>
                      <LoginCallback>
                        <LinearProgress />
                      </LoginCallback>
                    </Suspense>
                  }
                />
                <Route
                  path="/auth/logout"
                  element={
                    <Suspense>
                      <LogoutCallback />
                    </Suspense>
                  }
                />
                <Route path={`${cookieTenantId ? "" : "/:tenantId"}/:layoutName/*`} element={<App />} />
              </Routes>
            </BrowserRouter>
          )}
        </>
      )}
    </>
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <>
    <Suspense fallback={<LinearProgress />}>
      <TelemetryProvider instrumentationKey={window.__APPLICATION_INSIGHTS_KEY__}>
        <CoreApp />
      </TelemetryProvider>
    </Suspense>
    <ServiceWorkerUpdater />
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const location = document.location;
const params = new URLSearchParams(location.search);

if (params.get("neo.pwa") || params.get("neo.pwaInstall")) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
